import consumer from "~/channels/consumer"
const userToken = Math.random().toString(36).substr(2)

import { bookingTimesLoaded, updateCardWithAPIData, useHttpForBookingTimes } from "~/components/b2c/directory/bookableTimes"

let locationData = [];
let pendingLocationIds = [];
document.querySelectorAll('[data-booking-times-controller]').forEach(controllerEl => {
  controllerEl.querySelectorAll('[data-booking-times-card]').forEach(async (card) => {
    let location_data = {}
    location_data.location_id = card.dataset.segmentLocationId
    location_data.number_of_booking_times = parseInt(card.dataset.numberOfBookingTimes) || 4;
    location_data.prevent_waitlist = card.dataset.preventWaitlist == "true"; locationData.push(location_data)
    pendingLocationIds.push(card.dataset.segmentLocationId)
  })
})

consumer.subscriptions.create({ channel: "BookingTimesChannel", user_token: userToken, source: "directory", location_data: locationData }, {
  received(data) {
    let index = pendingLocationIds.indexOf(data.location_id)
    if (index > -1) {
      pendingLocationIds.splice(index, 1);
    }
    if (pendingLocationIds.length == 0) {
      consumer.disconnect()
      bookingTimesLoaded()
    }
    updateCardWithAPIData(data)
  }
})

setTimeout(() => {
  if (pendingLocationIds.length > 0) {
    consumer.disconnect()
    let locationsToRetry = []
    pendingLocationIds.forEach(locationId => {
      let card = document.querySelector(`.unified-listing-card.unified-listing-card--new[data-segment-location-id="${locationId}"]`);
      let locationToRetry = {
        id: locationId,
        prevent_waitlist: card.dataset.preventWaitlist == "true"
      }
      locationsToRetry.push(locationToRetry);
    })
    useHttpForBookingTimes(locationsToRetry)
  }
}, 5000);


// this delayed function is to report failures to load booking times. Uncomment this to initiate error reporting
// setTimeout(() => {
//   let currentPendingLocationIds = [];
//   document.querySelectorAll('[data-booking-times-controller]').forEach(controllerEl => {
//     controllerEl.querySelectorAll('[data-booking-times-card]').forEach(async (card) => {
//       if (card.dataset.slotsLoaded != "true") {
//         currentPendingLocationIds.push(card.dataset.segmentLocationId)
//       }
//     })
//   })

//   if (currentPendingLocationIds.length > 0) {
//     consumer.disconnect()
//     reportError(currentPendingLocationIds)
//   }
// }, 10000);